<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.materialCd" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="정비자재명"
                  name="materialName"
                  v-model="data.materialName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="MATERIAL_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="materialUnitCd"
                  label="단위"
                  v-model="data.materialUnitCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="대표단가"
                  name="price"
                  type="number"
                  suffix="원"
                  v-model="data.price">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="규격"
                  name="materialStandard"
                  v-model="data.materialStandard">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="재질"
                  name="materialQuality"
                  v-model="data.materialQuality">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-vendor
                  :editable="editable"
                  label="대표제조사"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-material
                  :editable="editable"
                  label="대체 자재"
                  name="supplantMaterialCd"
                  :supplantMaterialCd="popupParam.materialCd"
                  :plantCd="popupParam.plantCd"
                  v-model="data.supplantMaterialCd">
                </c-material>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :editable="editable"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="안전 재고량"
                  type="number"
                  name="safetyStock"
                  v-model="data.safetyStock">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="재 주문점(ROP)"
                  type="number"
                  name="ropStock"
                  v-model="data.ropStock">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="false"
                  label="현재 재고량"
                  type="number"
                  name="realStock"
                  v-model="data.realStock">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="grid1"
            title="자재 입고 이력"
            :columns="grid1.columns"
            :data="data.materialInData"
            :gridHeight="grid1.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
            selection="multiple"
            rowKey="materialInCd"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable && popupParam.materialCd" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable && popupParam.materialCd" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid2"
            title="자재 출고 이력"
            :columns="grid2.columns"
            :data="grid2.data"
            :gridHeight="grid2.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" style="padding-top:20px !important;">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="자재 사진 및 첨부파일">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid3"
            title="자재 실사이력"
            :columns="grid3.columns"
            :data="grid3.data"
            :gridHeight="grid3.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        materialCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MATERIAL_FILE',
        taskKey: '',
      },
      saveUrl: transactionConfig.sp.material.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      inDeleteUrl: '',
      materialoutUrl: '',
      materialhisUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      data: {
        plantCd: null,  // 사업장코드
        materialCd: '',  // 정비자재 일련번호
        materialName: '',  // 정비자재명
        materialUnitCd: null,  // 자재 단위
        materialStandard: '',  // 자재 규격
        materialQuality: '',  // 자재 재질
        price: null,  // 대표단가
        vendorCd: '',  // 제조사
        safetyStock: '',  // 안전재고량
        realStock: '',  // 현재재고량
        ropStock: '',  // 재주문점(ROP)
        delFlag: 'N',  // 삭제여부
        supplantMaterialCd: '',  // 대체자재
        materialInData: [], // 입고이력
      },
      grid1: {
        columns: [
          {
            name: 'inDt',
            field: 'inDt',
            label: '입고일',
            align: 'center',
            type: 'date',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'vendorCd',
            field: 'vendorCd',
            label: '공급업체',
            align: 'left',
            type: 'vendor',
            sortable: true,
          },
          {
            name: 'inStock',
            field: 'inStock',
            label: '입고수량',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inUnitPrice',
            field: 'inUnitPrice',
            label: '단가',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inTotalPrice',
            field: 'inTotalPrice',
            label: '총비용',
            type: 'cost',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px',
      },
      grid2: {
        columns: [
          {
            name: 'outDt',
            field: 'outDt',
            label: '출고일',
            align: 'center',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'workOrderName',
            field: 'workOrderName',
            label: '작업명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'outStock',
            field: 'outStock',
            label: '출고수량',
            type: 'cost',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px',
      },
      grid3: {
        columns: [
          {
            name: 'conductDt',
            field: 'conductDt',
            label: '실사일',
            align: 'center',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'conductStock',
            field: 'conductStock',
            label: '실사재고량',
            style: 'width:200px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'calcStock',
            field: 'calcStock',
            label: '재고 보정량',
            type: 'cost',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: '220px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sp.material.get.url;
      this.insertUrl = transactionConfig.sp.material.insert.url;
      this.updateUrl = transactionConfig.sp.material.update.url;
      this.deleteUrl = transactionConfig.sp.material.delete.url;
      this.inDeleteUrl = transactionConfig.sp.materialin.delete.url;
      this.materialoutUrl = selectConfig.sp.materialout.list.url;
      this.materialhisUrl = selectConfig.sp.conducthis.list.url;
      
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.materialCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {materialCd: this.popupParam.materialCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.materialCd)
          this.getOut();
          this.getConductHis();
        },);
      }
    },
    getOut() {
      if (this.popupParam.materialCd) {
        this.$http.url = this.materialoutUrl;
        this.$http.param = {materialCd: this.popupParam.materialCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    getConductHis() {
      if (this.popupParam.materialCd) {
        this.$http.url = this.materialhisUrl;
        this.$http.param = {materialCd: this.popupParam.materialCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid3.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.materialCd) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              
              let saveData = this.data.materialInData.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.data.materialInData = saveData;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.materialCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.materialCd = result.data.materialCd
      this.$set(this.attachInfo, 'taskKey', this.popupParam.materialCd)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addrow() {
      this.data.materialInData.splice(0, 0, {
        plantCd: this.data.plantCd,  // 사업장코드
        materialInCd: '',  // 정비자재 입고이력 일련번호
        materialCd: this.popupParam.materialCd,  // 정비자재 일련번호
        inDt: this.$comm.getToday(),  // 입고일
        vendorCd: '',  // 공급업체
        inStock: '',  // 입고수량
        inUnitPrice: '',  // 단가
        inTotalPrice: '',  // 총 비용
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeRow() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.inDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.materialInData = this.$_.reject(this.data.materialInData, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange(props) {
      if (props.row['inUnitPrice'] > 0 && props.row['inStock'] > 0) {
        this.$set(props.row, 'inTotalPrice', props.row['inStock'] * props.row['inUnitPrice']);
      }
    },
  }
};
</script>